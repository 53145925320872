@tailwind base;
@tailwind components;
@tailwind utilities;

:root {
    --main-text-color: rgba(0, 0, 0, 0.87);
    --main-background-color: rgba(247, 248, 251, 1);
    --alt-background-color: rgb(182, 182, 182);
}

@media (prefers-color-scheme: light) {
    :root {
        --main-text-color: rgba(0, 0, 0, 0.87);
        --main-background-color: rgba(247, 248, 251, 1);
        --alt-background-color: rgb(239, 239, 239);
        --alt-background-color-2: rgb(195, 195, 195);
    }
}

@media (prefers-color-scheme: dark) {
    :root {
        --main-text-color: rgba(204, 204, 204, 1);
        --main-background-color: rgba(48, 48, 48, 1);
        --alt-background-color: rgb(41, 41, 41);
        --alt-background-color-2: rgb(92, 92, 92);
    }
}

body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;

    color: var(--main-text-color);
    background-color: var(--main-background-color);
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

#root {
    display: contents;
}

html,
body {
    height: 100%;
}

@layer components {

    h1 {
        @apply text-2xl font-bold text-center;
    }

    article {
        @apply italic text-center lg:text-right mb-6;
    }

    h2 {
        @apply text-3xl mb-6 text-center lg:text-left;
    }

    h3 {
        @apply text-2xl font-bold mb-6;
        @apply lg:sticky lg:top-20 lg:bg-[--main-background-color] lg:shadow-md lg:p-2 lg:block;
        @apply before:lg:block before:lg:w-3 before:lg:h-full before:lg:bg-[--main-background-color] before:lg:absolute before:lg:-left-1 before:lg:top-0;
        @apply after:lg:block after:lg:w-3 after:lg:h-full after:lg:bg-[--main-background-color] after:lg:absolute after:lg:-right-1 after:lg:top-0;
    }

    h4 {
        @apply text-2xl my-10 text-center w-full md:w-1/4;
        @apply font-serif;

        --border-color: rgba(34,197,94,.5);
        --bg-color: rgba(34,197,94,.20);

        @apply relative cursor-pointer;
        @apply before:transition;
        @apply before:absolute before:inset-0 before:rounded;
        @apply hover:before:outline hover:before:outline-1 hover:before:outline-[--bg-color];

        &:not([class]) {
            @apply hover:before:shadow-md;
            @apply active:before:shadow-inner;
        }

        &.checked {
            @apply bg-no-repeat bg-[6px_center] bg-[url('../public/static/done.svg')];
            @apply before:border-l-4 before:border-l-[--border-color];
            @apply before:bg-gradient-to-r before:from-[--bg-color] before:to-transparent before:to-35%;
        }
    }

    blockquote {
        @apply ml-3 mb-3 border-l-4 rounded border-[--alt-background-color] p-3 italic;
    }

    p {
        @apply mb-3 indent-3 lg:text-lg;
    }

    img {
        @apply mb-3 rounded;
    }

    table {
        @apply mb-3;

        tr {
            th {
                @apply p-1 [&:first-child]:border-r border-[rgb(182,182,182)];
            }
            td {
                @apply border-t border-[rgb(182,182,182)] p-1;
                @apply [&:first-child]:border-r;
            }
        }
    }

    .footer-separator {
        @apply h-[1px] w-1/4 mt-20 mb-6 bg-[--alt-background-color-2] border-0;
    }

}
